import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    primary: {
      100: '#dde2fa',
      200: '#bbc5f5',
      300: '#99a8f0',
      400: '#778beb',
      500: '#556ee6',
      600: '#4458b8',
      700: '#33428a',
      800: '#222c5c',
      900: '#11162e',
    },
  },
  styles: {
    global: {
      body: {
        fontFamily: '"Poppins",sans-serif',
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#495057',
        background: '#f8f8fb',
        transitionProperty: null,
        transitionDuration: null,
        margin: 0,
      }
    }
  },
  components: {
      Badge: {
        baseStyle: {
          fontWeight: 600
        }
      }
  }
})

export default theme 