import React from 'react'
import PropTypes from "prop-types"

import { Spinner } from 'reactstrap'

const CustomSpinner = ({ isCentered, ...restProps }) => {
  const spinner = (
    <Spinner color="secondary" {...restProps}>
      Loading...
    </Spinner>
  )
  return isCentered ? (
    <div className="d-flex align-items-center justify-content-center">
      {spinner}
    </div>
  ) : (
    spinner
  )
}

CustomSpinner.propTypes = {
  isCentered: PropTypes.bool,
}

export default CustomSpinner
