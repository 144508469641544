import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import CustomSpinner from 'components/Common/CustomSpinner'
import CustomTheme from './theme'

if (process.env.REACT_APP_BUILD_TARGET === 'public') {
  const AppPublic = React.lazy(() => import('./AppPublic'))
  React.lazy(() => import('./i18n'))
  import('./store/public-store').then((s) => {
    const appPublic = (
      <Suspense fallback={<CustomSpinner isCentered />}>
        <Provider store={s.default}>
          <BrowserRouter basename="/">
            <React.Suspense fallback={<div></div>}>
              <AppPublic />
            </React.Suspense>
          </BrowserRouter>
        </Provider>
      </Suspense>
    )
    ReactDOM.render(appPublic, document.getElementById('root'))
  })
} else if (process.env.REACT_APP_BUILD_TARGET === 'admin') {
  const AppAdmin = React.lazy(() => import('./AppAdmin'))
  const ChakraProvider = React.lazy(() => import('chakra'))
  React.lazy(() => import('./i18n'))
  import('./store').then((s) => {
    const appAdmin = (
      <Provider store={s.default}>
        <React.Suspense fallback={<div></div>}>
          <ChakraProvider resetCSS={false} theme={CustomTheme}>
            <BrowserRouter basename="/">
              <AppAdmin />
            </BrowserRouter>
          </ChakraProvider>
        </React.Suspense>
      </Provider>
    )
    ReactDOM.render(appAdmin, document.getElementById('root'))
  })
} else {
  const AppBackend = React.lazy(() => import('./AppBackend'))
  const ChakraProvider = React.lazy(() => import('chakra'))
  React.lazy(() => import('./i18n'))
  import('./store').then((s) => {
    const appBackend = (
      <Provider store={s.default}>
        <React.Suspense fallback={<div></div>}>
          <ChakraProvider resetCSS={false} theme={CustomTheme}>
            <BrowserRouter basename="/">
              <AppBackend />
            </BrowserRouter>
          </ChakraProvider>
        </React.Suspense>
      </Provider>
    )
    ReactDOM.render(appBackend, document.getElementById('root'))
  })
}
serviceWorker.unregister()
